import React from 'react';
import Web3 from 'web3';



const WithdrawLoading = (props) => {
  return (

    <div className="row justify-content-center">
      <div className="col-1 justify-content-right pr-0">
        <div className="shape-img4 pl-5">


        </div>
      </div>
      <div className="col-2 pl-2">
        <p>Loading...</p>
      </div>
    </div>
  )
}

class Withdrawals extends React.Component {

  //Withdrawing function
  async wewithdraw() {

    console.log("<-- INSIDE WITHDRAWALS --> ")
    console.log("this.props.contractBalance --> ", this.props.contractBalance)

    if (this.props.contract !== 'undefined') {
      try {

        await this.props.contract.methods.withdraw().send({ from: this.props.account })

      } catch (e) {
        console.log('Error, withdraw: ', e)
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      contractBalance: this.props.contractBalance,
      action: "wait",
    };

    // This binding is necessary to make `this` work in the callback    this.handleClick = this.handleClick.bind(this);
  }


  render() {
    return (
      <section className="what-we-do-area bg-black pt-5">

        <div className="container ">
          {this.props.ownerAccount != null ?
            <div className="row justify-content-center">

              <div className="col-md-8 col-sm-10 col-sm-push-1 justify-content-center">
                <div className="d-flex flex-column justify-content-center text-center">

                  <h4>Contract Balance :<span style={{ color: '#F2356D' }}> {this.props.contractBalance / (10 ** 18)}</span> ETH -- Action {this.state.action} </h4>
                  <h4>Full Sale status : {this.props.FullSaleOK ? <span style={{ color: '#F2356D' }}> Active </span> : "Not Active"} </h4>
                  <h4>Pre Sale status (can only buy if on WL) : {this.props.PreSaleOK ? <span style={{ color: '#F2356D' }}> Active </span> : "Not Active"} </h4>
                   <h4>VIPWhiteListingOpen : {this.props.VIPWhiteListingOpen ? <span style={{ color: '#F2356D' }}> Active </span> : "Not Active"} </h4>
                  <hr />
                  <p align="center"> Just click to withdraw the ETH to : {this.props.ownerAccount}
                  </p>

                  <form className="" onSubmit={(e) => {
                    e.preventDefault()    //doesnt let the page refresh

                    this.wewithdraw()

                  }}>

                    <input
                      type='text'
                      className="form-control"
                      placeholder='Nothing to write'
                      ref={(input) => { this.msize = input }}
                    />

                    <input type='submit' className='btn btn-block btn-primary mb-3' value='WITHDRAW Balance' />

                  </form>
                </div>

              </div>


            </div>
            : <WithdrawLoading />}
        </div>


      </section>
    )
  }
}

export default Withdrawals;
