import React from 'react';
import { useRecoilState, RecoilRoot } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'

const NavbarTwoCustom = () => {

    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        //window.scrollTo(0, 0);
    })

    return (
        <React.Fragment>
        </React.Fragment>
    );
}

export default NavbarTwoCustom;
