import React from 'react';
import Web3 from 'web3';
import axios from 'axios';


const WhishslistLoading = (props) => {
  return (

    <div className="row justify-content-center">
      <div className="col-1 justify-content-right pr-0">
        <div className="shape-img4 pl-5">

        </div>
      </div>
      <div className="col-2 pl-2">
        <p>Loading...</p>
      </div>
    </div>
  )
}

class Whishslists extends React.Component {

  //Withdrawing function
  async weprocessWL(wl) {



    if ((this.props.contract !== 'undefined') && wl.length>0) {
      try {

        //set that value based on whether the associated check box is checkd or not
        // if the checkbox is ticked, also marked them as processed in the DB

        if (this.state.autoMarkChk == true){


                    for (var j = 0; j < wl.length; j++){
                        //console.log("---------loop in wl--------")
                        //console.log(wl[j])

                        //sending the request to change the value in the db
                        const res = await axios.get("/markaddressassent2contract?address="+wl[j], {
                          headers: {
                            'Accept': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers': 'Content-Type'
                          }
                        });

                    }

        }


        //console.log("WHITELIST inside the function",wl);
        await this.props.contract.methods.addAddressesToWhiteList(wl).send({ from: this.props.account });




      } catch (e) {
        console.log('Error, weprocessWL: ', e)
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      contractBalance: this.props.contractBalance,
      autoMarkChk: true,
      textArea: this.props.whishslist,
      action: "wait",
    };

    this.handleChangAutoMarkChk = this.handleChangAutoMarkChk.bind(this)
    this.handleTextAreaChang = this.handleTextAreaChang.bind(this)
  }

  handleChangAutoMarkChk() {

    this.setState({autoMarkChk:!this.state.autoMarkChk})

  }

  handleTextAreaChang(e){
    this.props.updateWhishslists(e.target.value.split(","))
  }


  render() {
    return (
      <section className="what-we-do-area bg-black pb-5">

        <div className="container ">
          {this.props.ownerAccount != null ?
            <div className="row justify-content-center">

              <div className="col-md-8 col-sm-10 col-sm-push-1 justify-content-center">
                <div className="d-flex flex-column justify-content-center text-center">
                <div>{typeof this.props.whishslist}</div>
  

    
                  <form className="" onSubmit={(e) => {
                    e.preventDefault()    //doesnt let the page refresh
                    console.log(this.props.whishslist)
                    var addressList = this.props.whishslist;
                    //console.log(typeof addressList)
                    //console.log(addressList)
                    this.weprocessWL(addressList)

                  }}>

                    <textarea
                      id = "listofadff"
                      className="form-control"
                      placeholder=' Address list ex: zzzz,xxxx,yyyy '
                      rows="5"
                      value={this.props.whishslist}
                      onChange={this.handleTextAreaChang}
                      //ref={(input)=>{this.textArea = input }}
                     
                    />
                    <label className="form-check-label pl-2" htmlFor="flexCheckDefault">
   
                        <p> The <span style={{ color: '#F2356D' }}>{this.props.whishslist.length}</span> Address(es) have been imported.</p>
                   
                    </label>
                      <div className="form-check">
         
                        <input type="checkbox" defaultChecked={this.state.autoMarkChk} id="flexCheckDefault" onChange={this.handleChangAutoMarkChk} />
                        <label className="form-check-label pl-2" htmlFor="flexCheckDefault">
                           { this.state.autoMarkChk?
                            <p>Enable "added to contract"</p>
                           :
                            <p>Disable "added to contract"</p>
                           }
                        </label>
                      </div>
                    <input type='submit' className='btn btn-block btn-primary mb-3' value='BULK WHITELIST - Should no include quote signs' />

                  </form>

                </div>

              </div>

            </div>
            : <WhishslistLoading />}
        </div>

      </section>
    )
  }
}

export default Whishslists;
